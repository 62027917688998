export enum API_TYPES {
  AI_TECH_BRIEF_API = 'AI_TECH_BRIEF_API',
  ASSISTANT_API = 'ASSISTANT_API',
  COLLIDER_API = 'COLLIDER_API',
}

const developmentApi: Record<API_TYPES, string> = {
  // ASSISTANT_API: 'https://open-pa-api-ch-elektra.os-dev.io/api',
  AI_TECH_BRIEF_API: 'https://ai-tech-brief-api-ch-witch.os-dev.io/api',
  ASSISTANT_API: 'https://open-pa-api-ch-witch.os-dev.io/api',
  COLLIDER_API: 'https://collider-api-ch-pitch.wppopen.com/api/workfront',
}

const productionApi: Record<API_TYPES, string> = {
  AI_TECH_BRIEF_API: 'https://ai-tech-brief-api-ch-hydra.os-dev.io/api',
  ASSISTANT_API: 'https://open-pa-api-ch-hydra.os-dev.io/api',
  COLLIDER_API: 'https://collider-api-ch-hydra.os-dev.io/api/workfront',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
